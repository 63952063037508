import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UserContext } from 'globalContext/UserContext';
import moment from 'moment';
import { userProfileUrl } from 'router/url';
import CustomFieldsTable from 'components/Table/CustomFieldsTable';
import { ClientCustomFields } from 'components/Table/types';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { getFetch } from 'utils/fetchFunctions';
import { returnHourDate } from 'utils/formatters';
import { useToggleState } from 'utils/hooks/useToggleState';
import EditDialog from '../../Dialogs/EditDialog';
import { AccountData, AccountResponse, GeneralTabProps } from '../../types';
import * as Styles from './GeneralTab.css';

const GeneralTab = ({ setIsB2B }: GeneralTabProps) => {
  const intl = useIntl();
  const [userData, setUserData] = useState<AccountData>();
  const [customFieldsData, setCustomFieldsData] = useState<
    ClientCustomFields[] | undefined
  >();
  const [showEditDialog, handleShowEditDialog] = useToggleState(false);
  const { username } = useContext(UserContext);

  const fetchUserData = useCallback(async () => {
    if (!username) {
      return;
    }

    try {
      const data: AccountResponse = await getFetch({
        url: `${userProfileUrl}/${username}/profile`,
      });

      const userInfoData = await getFetch({
        url: `${userProfileUrl}/${username}/info`,
      });

      const personalId = userInfoData?.personalId || '';
      const externalEmployeeId = userInfoData?.externalEmployeeId || '';

      const {
        project,
        client,
        contractType,
        startTime,
        customFields,
        employer,
      } = data;

      const userRenamedData = {
        project: project ? { name: project.name, value: project.id } : null,
        client: client ? { name: client.name, value: client.id } : null,
        employer: employer ? { name: employer.name, value: employer.id } : null,
        contractType: contractType
          ? {
              name: contractType?.replace('_', ' '),
              value: contractType,
            }
          : null,
        startTime: startTime ? new Date(returnHourDate(startTime)) : '',
        personalId,
        externalEmployeeId,
      };
      setIsB2B(data?.contractType === ContractTypes.B2B);
      setCustomFieldsData(customFields);
      setUserData(userRenamedData);
    } catch (e) {
      return;
    }
  }, [username, setIsB2B]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const isCustomFieldValid = !!customFieldsData?.length;

  return (
    <Styles.GridWrapper container spacing={2}>
      <Grid item xs={isCustomFieldValid ? 6 : 8}>
        {userData && (
          <Paper elevation={3}>
            <Styles.HeaderWrapper>
              <Styles.UserTitleWrapper>
                {intl.formatMessage({ id: 'USER_PROFILE.ACCOUNT_INFO' })}
              </Styles.UserTitleWrapper>
              <Styles.IconWrapper>
                <IconButton
                  aria-label="edit user data"
                  component="span"
                  onClick={handleShowEditDialog}
                >
                  <Styles.UpdateIcon />
                </IconButton>
              </Styles.IconWrapper>
            </Styles.HeaderWrapper>
            <Styles.DataWrapper container spacing={1}>
              <Grid item xs={6}>
                <Styles.StyledFieldName
                  $hasValue={Boolean(userData?.employer?.name)}
                >
                  {intl.formatMessage({ id: 'TABLE.HEAD.EMPLOYER' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.employer?.name || ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName
                  $hasValue={Boolean(userData?.contractType?.name)}
                >
                  {intl.formatMessage({ id: 'USER_PROFILE.CONTRACT_TYPE' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.contractType?.name
                    ? intl.formatMessage({
                        id: `BUSINESS_TRIP.${userData?.contractType?.name
                          .split(' ')
                          .join('_')}`,
                      })
                    : ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName
                  $hasValue={Boolean(userData?.project?.name)}
                >
                  {intl.formatMessage({ id: 'USER_PROFILE.DEFAULT_PROJECT' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.project?.name || ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName
                  $hasValue={Boolean(userData?.client?.name)}
                >
                  {intl.formatMessage({ id: 'USER_PROFILE.DEFAULT_CLIENT' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.client?.name || ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName
                  $hasValue={Boolean(userData?.startTime)}
                >
                  {intl.formatMessage({
                    id: 'USER_PROFILE.DEFAULT_START_TIME',
                  })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.startTime
                    ? moment(userData.startTime).format('HH:mm')
                    : ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName $hasValue={true}>
                  {intl.formatMessage({ id: 'USER_PROFILE.PERSONAL_ID' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                  {userData?.personalId || ''}
                </Styles.StyledFieldValue>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldName $hasValue={true}>
                    {intl.formatMessage({ id: 'USER_PROFILE.EXTERNAL_EMPLOYEE_ID' })}
                </Styles.StyledFieldName>
              </Grid>
              <Grid item xs={6}>
                <Styles.StyledFieldValue>
                    {userData?.externalEmployeeId || ''}
                </Styles.StyledFieldValue>
              </Grid>
            </Styles.DataWrapper>
          </Paper>
        )}
      </Grid>
      {isCustomFieldValid && (
        <Grid item xs={6}>
          <CustomFieldsTable
            customFieldsData={customFieldsData}
            fetchCustomFields={fetchUserData}
            username={username}
          />
        </Grid>
      )}
      {showEditDialog && (
        <EditDialog
          isDialogOpen={showEditDialog}
          handleShowClicked={handleShowEditDialog}
          data={userData}
          fetchData={fetchUserData}
        />
      )}
    </Styles.GridWrapper>
  );
};

export default GeneralTab;
