import {
  Card,
  Grid,
  GridProps,
  TableCell,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { SortAsc, SortDesc, TableRow } from 'components/Table/Table.css';

interface GridItemProps extends GridProps {
  isBorder?: boolean;
}

interface InfoContainerProps extends GridProps {
  $centered?: boolean;
  $bottomSpace?: boolean;
}

interface StepGroupProps extends GridProps {
  isFirst?: boolean;
  justifyStart?: boolean;
}

interface TextProps {
  $isInline?: boolean;
  $noMarginBottom?: boolean;
  $isSmall?: boolean;
}

interface InfoWrapperProps {
  noPadding?: boolean;
}


export const StyledFormControl = styled('form')`
  display: block;
  padding-top: 2rem;
  padding-bottom: 1rem;
  max-width: 90%;
  margin: 0 auto;
`;

export const GridGroup = styled(Grid)`
  &.MuiGrid-container {
    position: relative;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const GroupTitle = styled('span')`
  display: inline-block;
  padding: 0 10px;
  background-color: white;
  color: #000;
  position: absolute;
  top: -13px;
  left: 7.5%;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const GridInfo = styled(Grid)`
  &.MuiGrid-container {
    margin-top: -1rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }
`;


const border = '1px solid rgba(224, 224, 224, 1)';

export const GridWithMargin = styled(Grid)`
  &.MuiGrid-item {
    margin-bottom: 1rem;
    margin-right: 0;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: none;
  }
`;

export const StyledFirstCell = styled(StyledTableCell)`
  &.MuiTableCell-root {
    position: relative;
    padding-left: 3rem;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    border-top: ${border};
  }
`;
export const ButtonWithMargin = styled(Grid)`
  &.MuiGrid-item {
    margin-right: 2rem;
  }
`;

export const CollapsableTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
  }
`;

export const ExpandMoreButton = styled(SortDesc)`
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(50%) !important;
`;

export const ShowLessButton = styled(SortAsc)`
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(50%) !important;
`;

export const GridContainer = styled(Grid)`
  &.MuiGrid-container {
    padding: 6px 0;
    border-top: ${border};
  }
`;

export const GridItem = styled(Grid)<GridItemProps>`
  &.MuiGrid-item {
    border-left: ${({ isBorder }) => (isBorder ? border : 'none')};
    padding: 16px;
  }
`;

export const PrimaryTitle = styled('h3')<TextProps>`
  margin: 0 0 0.5rem 0.5rem;
  display: ${({ $isInline }) => ($isInline ? 'inline-block' : 'block')};
  margin-bottom: ${({ $noMarginBottom }) => ($noMarginBottom ? '0' : '0.5rem')};
  font-size: ${({ $isSmall }) => ($isSmall ? '11.5px' : '13px')};
`;

export const InlinePrimaryTitle = styled(PrimaryTitle)`
  margin-right: 0.3rem;
  display: inline;
`;

export const SecondaryTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12.5px;
    font-weight: 600;
  }
`;

export const TertiaryTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    padding-top: 3px;
  }
`;

export const BorderedName = styled('span')`
  display: inline-block;
  border: ${border};
  border-radius: 1rem;
  padding: 0.3rem 0.8rem;
  margin: 0.2rem;
  font-size: 12px;
`;

export const StyledCard = styled(Card)`
  margin-bottom: 0.5rem;
`;

export const InfoWrapper = styled('div')<InfoWrapperProps>`
  padding: ${({ noPadding }) => (noPadding ? 0 : '8px 0')};
  margin-bottom: 0.5rem;
`;

export const InputSelectTitle = styled(Typography)`
  &.MuiTypography-root {
    margin: 1rem 0 0 1rem;
  }
`;

export const StepGroup = styled(GridGroup)<StepGroupProps>`
  &.MuiGrid-container {
    padding: 1.5rem 0;
    margin-top: ${({ isFirst }) => (isFirst ? '2.5rem' : '1rem')};
    justify-content: ${({ justifyStart }) =>
      justifyStart ? 'flex-start' : 'space-evenly'};
  }
`;

export const StepTitle = styled(GroupTitle)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`;

export const InfoContainer = styled(Grid)<InfoContainerProps>`
  text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};
  margin-bottom: ${({ $bottomSpace }) => ($bottomSpace ? '0.8rem' : '0')};
  padding: ${({ $centered }) =>
    $centered ? '0.4rem 0' : '0.4rem 0 0.4rem 1rem'};
`;

export const InputTitle = styled('span')`
  font-weight: 600;
  font-size: 14px;
  margin-right: 0.4rem;
`;

export const Paragraph = styled('p')`
  font-size: 13px;
  margin: 0.4rem 0;
`;

export const SmallParagraph = styled('p')<TextProps>`
  display: ${({ $isInline }) => ($isInline ? 'inline-block' : 'block')};
  font-size: 12px;
  margin: 0.5rem;
`;

export const BorderedGrid = styled(Grid)`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1rem 0.5rem;
`;

export const GridTitle = styled(Grid)`
  padding-top: 1rem;
  padding-bottom: 0.7rem;
`;

export const InputContent = styled.span`
  display: block;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;
