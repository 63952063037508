import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Chip, LinearProgress, Button, Box } from '@material-ui/core';
import {
  ErrorOutlineIcon,
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
} from 'components/Table/Table.css';
import { dateFormatFrontend, formatDate } from 'utils/dateFormats';
import { TimesheetImportHistoryEntry, ImportErrorsEntry, Props } from './types';
import { TABLE_HEAD_DATA as head } from './consts';
import { StyledTableIconButton } from 'components/AccountingNotesList/AccountingNotes.css';

export const TimesheetImportHistoryTable = ({ loading, data, index, setOpen, setImportErrorsEntry }: Props) => {
  const intl = useIntl();
  const addErrorDetails = useCallback(
      (infoErrors: ImportErrorsEntry[], index: number) =>
        async () => {
           setOpen(true);
           setImportErrorsEntry(infoErrors);
        },
      [],
    );

  const renderTableHeadRow = () =>
    head.map((headData, index) => (
      <TableCell
        align={headData.align}
        key={`${index} - ${headData.key}`}
        width={headData.width}
      >
        {intl.formatMessage({ id: headData.label })}
      </TableCell>
    ));

  return (
    <TableContainer component={Paper} $loading={loading}>
      {loading && <LinearProgress />}
      <TableComponent>
        <TableHeadComponent>
          <TableRow $main>{renderTableHeadRow()}</TableRow>
        </TableHeadComponent>
        <TableBody>
          {data?.map((entry, index) => (
            <TableRow index={index} key={entry.uploadDate}>
              <TableCell align="left">{entry.account.fullName}</TableCell>
              <TableCell align="left">
                {formatDate(entry.uploadDate, dateFormatFrontend)}
              </TableCell>
              <TableCell align="left">
                {formatDate(entry.endDate, dateFormatFrontend)}
              </TableCell>
              <TableCell align="left">{entry.filename}</TableCell>
              <TableCell align="left">
                <Chip label={entry.importStatus} color="secondary" />
              </TableCell>
              <TableCell align="left">
              <StyledTableIconButton
                              id="errorDetails"
                              disabled={entry.importStatus !== 'ERROR'}
                              onClick={addErrorDetails(entry.importErrors, index)}
                            >
               <ErrorOutlineIcon />
                </StyledTableIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  );
};
