import { useCallback, useContext, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { UserContext } from 'globalContext/UserContext';
import moment, { Moment } from 'moment';
import { ContainedButton } from 'components/Button';
import ControlledStepper from 'components/ControlledStepper';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import { SmallUploadFileIcon } from 'modules/BusinessTrips/BusinessTrips.css';
import { fetchBenefitsUploadHistory } from 'utils/commonFetches';
import { returnRoleInclusion } from 'utils/helpers';
import { RoleTypes } from 'utils/rolesTypes';
import { ButtonWithMargin, GridWithMargin } from './TimesheetImport.css';
import { UploadDialog } from './components/UploadDialog';
import { TimesheetImportHistoryEntry, ImportErrorsEntry } from './components/TimesheetImportHistoryTable/types';
import { TimesheetImportHistoryTable } from './components/TimesheetImportHistoryTable/TimesheetImportHistoryTable';
import { timesheetHistoryImportUrl } from 'router/url';
import { getFetch } from 'utils/fetchFunctions';
import { ImportErrorDialog } from './components/ImportErrorDialog';

const TimesheetImport = () => {
  const [loading] = useState(false);
  const [isVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const index = 0;
  const intl = useIntl();
  const [isUploadDialogOpen, setUploadDialogOpen] =useState(false);
  const [monthAndYear, setMonthAndYear] = useState<Moment | null>(moment());
  const { roles } = useContext(UserContext);
  const [timesheetImportHistory, setTimesheetImportHistory] = useState<TimesheetImportHistoryEntry[]>([]);
  const [isImportErrorsEntry, setImportErrorsEntry] = useState<ImportErrorsEntry[]>([]);
  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]),
    [roles],
  );

  const fetchTimeSheetImportHistory = async (setState: Dispatch<SetStateAction<TimesheetImportHistoryEntry[]>>,yearMonth: string) => {
    const response = await getFetch({url: `${timesheetHistoryImportUrl}?yearMonth=${yearMonth}`})
    setState(response)
    return response
  }

  const onDialogSubmit = ()=> { fetchTimeSheetImportHistory(setTimesheetImportHistory,moment(monthAndYear).format('YYYY-MM')); }

  useEffect(() => {
    fetchTimeSheetImportHistory(setTimesheetImportHistory,moment(monthAndYear).format('YYYY-MM'));
  }, [monthAndYear]);

  return (
    <>
      <Grid container>
        <GridWithMargin container item justify="space-between" spacing={2}>
          {isAccountPrivileged && (
            <ButtonWithMargin item>
              <ContainedButton
                endIcon={<SmallUploadFileIcon />}
                onClick={() => setUploadDialogOpen(true)}
                size="large"
                id="uploadCostsSummary"
              >
                {intl.formatMessage({
                  id: 'TIMESHEET_IMPORT.UPLOAD_TIMESHEET',
                })}
              </ContainedButton>
            </ButtonWithMargin>
          )}
         <DatePicker
                  value={monthAndYear}
                  onChange={setMonthAndYear}
                  inputVariant="outlined"
                  views={['month', 'year']}
                  format="MM-YYYY"
                  label={intl.formatMessage({
                    id: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.DATE',
                  })}
        />
        </GridWithMargin>
        <Grid item xs={12}>
            <TimesheetImportHistoryTable loading={loading} data={timesheetImportHistory}
            index={index} setOpen={setOpen} setImportErrorsEntry={setImportErrorsEntry}/>
        </Grid>
      </Grid>
      <UploadDialog
            isUploadDialogOpen={isUploadDialogOpen}
            setUploadDialogOpen={setUploadDialogOpen}
            onDialogSubmit={onDialogSubmit}
      />
      <ImportErrorDialog
              isOpen={isOpen}
              setOpen={setOpen}
              isImportErrorsEntry={isImportErrorsEntry}
      />
    </>
  );
};

export default TimesheetImport;
