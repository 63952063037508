import { useIntl } from 'react-intl';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { ImportErrorDialogProps, ImportErrorsEntry } from './types'
import ContainedButton from 'components/Button/ContainedButton';
import { TABLE_HEAD_DATA as head } from './consts';

import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapperFlex,
  DialogTitleWrapper,
  DialogWrapper,
} from 'components/Dialog/Dialog.css';
import {
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
} from 'components/Table/Table.css';

export const ImportErrorDialog = ({ isOpen, setOpen, isImportErrorsEntry}: ImportErrorDialogProps) => {
  const intl = useIntl();

  const handleClose = async () => {
    setOpen(false);
  }

  const renderTableHeadRow = () =>
      head.map((headData, index) => (
        <TableCell
          align={headData.align}
          key={`${index} - ${headData.key}`}
          width={headData.width}
        >
          {intl.formatMessage({ id: headData.label })}
        </TableCell>
      ));

  return (
       <DialogWrapper
             fullScreen={false}
             fullWidth
             maxWidth="md"
             open={isOpen}
             onClose={handleClose}>
             <DialogTitleWrapper color="primary">
               <Grid container direction="row" alignItems="center">
                 <Grid xs={12} sm={10} item container justify="flex-start">
                  {intl.formatMessage({ id: 'TIMESHEET_IMPORT.UPLOAD_ERROR.TITLE' })}
                 </Grid>
                 <Grid xs={12} sm={2} item container justify="flex-end">
                   <IconButton
                     id="closeButton"
                     aria-label="close"
                     onClick={handleClose}>
                     <CloseIcon />
                   </IconButton>
                 </Grid>
               </Grid>
             </DialogTitleWrapper>
             <DialogContentWrapperFlex>
               <Grid xs={11}>
                <TableContainer component={Paper}>
                      <TableComponent>
                        <TableHeadComponent>
                          <TableRow $main>{renderTableHeadRow()}</TableRow>
                        </TableHeadComponent>
                            <TableBody>
                              {isImportErrorsEntry?.map((entry, index) => (
                                <TableRow index={index}>
                                  <TableCell align="left">{entry.message}</TableCell>
                                  <TableCell align="left">{entry.externalUsername}</TableCell>
                                  <TableCell align="left">{entry.timeportsUsername}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                        </TableComponent>
                </TableContainer>
               </Grid>
             </DialogContentWrapperFlex>
             <DialogActionWrapper>
               <Grid xs={12} item>
                 <ContainedButton
                   id="closeButton"
                   fullWidth
                   onClick={handleClose}
                   size="large"
                   color="secondary"
                   type="submit"
                 >
                   {intl.formatMessage({ id: 'TIMESHEET_IMPORT.UPLOAD_ERROR.CLOSE' })}
                 </ContainedButton>
               </Grid>
             </DialogActionWrapper>
           </DialogWrapper>
  );
};

export default ImportErrorDialog;
