import { useContext, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UserContext } from 'globalContext/UserContext';
import MenuBar from 'components/MenuBar';
import { returnRoleInclusion } from 'utils/helpers';
import { RoleTypes } from 'utils/rolesTypes';
import BenefitsManager from './Screens/BenefitsManager';
import { BenefitManagerProvider } from './Screens/BenefitsManager/BenefitManagerContext/Context';
import TimesheetImport from './Screens/TimesheetImport';
import CalendarComponent from './Screens/CalendarComponent';
import EmployeesHolidaysComponent from './Screens/EmployeesHolidaysComponent';
import ExcludeTimesheetComponent from './Screens/ExcludeTimesheetComponent';
import ManualAcceptanceTable from './Screens/ManualAcceptanceTable';
import PermissionManager from './Screens/PermissionManager';
import { VIEW_MODES } from './consts';
import {
  MANUAL_ACCEPTANCE_HEAD_DATA as tableHeadData,
  TAB_LABELS,
} from './static_data';
import { GridWrapper } from './AdminPanel.css';

const AdminPanel = () => {
  const [viewMode, setViewMode] = useState(VIEW_MODES.MANUAL_ACCEPTANCE);
  const { roles } = useContext(UserContext);

  const getTabWrapper = (tabComponent: JSX.Element) => (
    <>
      <GridWrapper
        container
        spacing={1}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          {tabComponent}
        </Grid>
      </GridWrapper>
    </>
  );

  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]),
    [roles],
  );

  const getTabOptions = () => {
    if (isAccountPrivileged) {
      return TAB_LABELS;
    }
    return TAB_LABELS.filter(
      (tab) =>
        tab.key !== VIEW_MODES.CALENDAR &&
        tab.key !== VIEW_MODES.BENEFITS_MANAGER &&
        tab.key !== VIEW_MODES.PERMISSION_MANAGER &&
        tab.key !== VIEW_MODES.TIMESHEET_IMPORT,
    );
  };

  const renderTab = useMemo(() => {
    switch (viewMode) {
      case VIEW_MODES.MANUAL_ACCEPTANCE:
        return getTabWrapper(<ManualAcceptanceTable head={tableHeadData} />);
      case VIEW_MODES.CALENDAR:
        if (isAccountPrivileged) {
          return getTabWrapper(<CalendarComponent />);
        }
        return;
      case VIEW_MODES.HOLIDAYS:
        return getTabWrapper(<EmployeesHolidaysComponent />);
      case VIEW_MODES.BENEFITS_MANAGER:
        if (isAccountPrivileged) {
          return getTabWrapper(
            <BenefitManagerProvider>
              <BenefitsManager />
            </BenefitManagerProvider>,
          );
        }
        return;
      case VIEW_MODES.EXCLUDE:
        return getTabWrapper(<ExcludeTimesheetComponent />);
      case VIEW_MODES.PERMISSION_MANAGER:
        return getTabWrapper(<PermissionManager />);
      case VIEW_MODES.TIMESHEET_IMPORT:
              if (isAccountPrivileged) {
                return getTabWrapper(
                        <TimesheetImport />
                    );
              }
              return;
    }
  }, [isAccountPrivileged, viewMode]);

  return (
    <>
      <MenuBar
        value={viewMode}
        setValue={setViewMode}
        options={getTabOptions()}
      />
      <Box overflow="auto" padding="2rem" minHeight="100%">
        {renderTab}
      </Box>
    </>
  );
};

export default AdminPanel;
