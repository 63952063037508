import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TABLE_HEAD_DATA: TableHead[] = [
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.NAME',
    key: 'account',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleDEVHR,
    width: '25%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.UPLOAD_DATE',
    key: 'uploadDate',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.END_DATE',
    key: 'endDate',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.FILENAME',
    key: 'filename',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '25%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.IMPORT_STATUS',
    key: 'importStatus',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_HISTORY.IMPORT_ERRORS',
    key: 'importErrors',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },

];
