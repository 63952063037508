import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TABLE_HEAD_DATA: TableHead[] = [
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_ERROR.MESSAGE',
    key: 'message',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleDEVHR,
    width: '33%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_ERROR.EXTERNAL_USERNAME',
    key: 'externalUsername',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '33%',
  },
  {
    label: 'TIMESHEET_IMPORT.UPLOAD_ERROR.TIMEPORTS_USERNAME',
    key: 'timeportsUsername',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '33%',
  },

];