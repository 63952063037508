import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { timesheetImportUrl } from 'router/url';
import ControlledCheckbox from 'components/ControlledCheckbox';
import ControlledDatePicker from 'components/ControlledDatePicker';
import { ContentDialog } from 'components/Dialog/ContentDialog';
import FilesUploadArea from 'components/FileUploadArea/FilesUploadArea';
import { Tooltip } from 'components/Tooltip';
import { postFormDataWithAuthorization } from 'utils/fetchFunctions';
import {
  UploadDialogProps,
  UploadDialogForm,
} from './UploadDialog.types';
import {
  CheckboxWrapper,
  ContentWrapper,
  UploadDialogWrapper,
} from './UploadDialog.css';

const yearMonthFormat = 'yyyy-MM';

export const UploadDialog = ({
  isUploadDialogOpen,
  setUploadDialogOpen,
  onDialogSubmit,
}: UploadDialogProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const intl = useIntl();
  const token = localStorage.getItem('tpToken');
  const { control, getValues } = useForm<UploadDialogForm>({
    defaultValues: {
      yearMonth: format(new Date(), yearMonthFormat)
    },
  });

  const handleSetSelectedFiles = (uploadFiles: File[]) => {
    setSelectedFiles(uploadFiles);
  };

  const handleSubmitForm = async () => {
    const { yearMonth } = getValues();

    selectedFiles.map(async (file) => {
      const requestBodyData = {
        file,
        yearMonth: format(new Date(yearMonth), yearMonthFormat),
      };

      const formData = new FormData();

      for (const [key, value] of Object.entries(requestBodyData)) {
        formData.append(key, value);
      }

      try {
        const response = await postFormDataWithAuthorization({
          url: `${timesheetImportUrl}`,
          body: formData,
          label: 'TIMESHEET_IMPORT',
        });

        if (response.ok) {
          toast.success(
            intl.formatMessage(
              { id: 'TIMESHEET_IMPORT.TOAST_ACCEPT' },
              { fileName: file.name },
            ),
          );
        }
        onDialogSubmit();
      } catch (e) {
        toast.error(
          intl.formatMessage(
            { id: 'TIMESHEET_IMPORT.TOAST_DENIED' },
            { fileName: file.name },
          ),
        );
      }
    });
    setUploadDialogOpen(false);
  };

  return (
    <ContentDialog
      isOpen={isUploadDialogOpen}
      setIsOpen={setUploadDialogOpen}
      headerTitle="TIMESHEET_IMPORT.UPLOAD_TIMESHEET"
      confirmButtonContent="DIALOG.SEND"
      handleConfirm={handleSubmitForm}
      disableConfirmButton={!selectedFiles.length}
    >
      <ContentWrapper>
        <form onSubmit={handleSubmitForm}>
          <FilesUploadArea handleFileChange={handleSetSelectedFiles} />
          <UploadDialogWrapper>
            <ControlledDatePicker
              name="yearMonth"
              label={intl.formatMessage({
                id: 'TIMESHEET_IMPORT.UPLOAD_DIALOG.DATE',
              })}
              views={['year', 'month']}
              format={yearMonthFormat}
              control={control}
            />
          </UploadDialogWrapper>
        </form>
      </ContentWrapper>
    </ContentDialog>
  );
};
